import { useState } from "react";
import useMountTransition from "./useMountTransition";
import "./styles.css";


export function UsaMap() {
    const [isMounted, setIsMounted] = useState(true);
    const hasTransitionedIn = useMountTransition(isMounted, 1000);
    
    /*
     * Read the blog post here:
     * https://letsbuildui.dev/articles/how-to-animate-mounting-content-in-react
     */
    return (
      <div className="container">
        
  
        <div className="content">
          
          
  
        <svg
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 712.19 453.8">
      <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
              <UsaState d="M471.85,161.2l-.4,11,1.9,9.5v10.4l1.3,10.3-.1.2s-.9,3,.7,4.5l.2.2.1.3c.2.9,1.2,5.7-.9,7.3-.7,2.7-1.7,5.9-3.3,6.7l-.4,3.6,6.2-1.2,2.8,1.7,1.3.5c.8-1.5,2.1-3.6,3.9-3.6,1.3,0,2.3,1,3.1,2.9h.1c.2,0,.3,0,.4-.1.3-.2.5-1.1.1-2.9l-.1-.6,4.6-4v3.2l2.7.2v-2.6l6-6,.7-5h.9c1.5,0,4.9-.5,5.7-1.8a1.58,1.58,0,0,0-.1-1.5l-.1-.3-.7-7.1-1.3-9.4-.6-10.3-1.3-8.5-1.7-6.3.4-4.5-4.9-.6-4.4.7h-5.3a43,43,0,0,1-7.1.7h-.4C476.75,165.7,473.55,163.9,471.85,161.2Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M593.35,182.9h0l-4.8-.6-2,3.3H584l-3,4.3-3.5.3-.5-8-12.9,1.7-2.2-9.6-.2.7-.1,9.7-.1.2a48.45,48.45,0,0,1-5.4,7.5l-.8.8-.8-.8a5.06,5.06,0,0,0-.9-.8v.5l-.4.4c-2.6,2.4-1.9,3.5-1.9,3.5l.2.3v.1c.8,2.3.8,3.7-.1,4.4a1.9,1.9,0,0,1-1.3.5,3,3,0,0,1-1.2-.3h-.1l-.3-.2h0c-.9-1.1-1.3-1.1-1.3-1.1-.3.3-.7,3.1-.3,6.6l.1.9-1.9.5a3.4,3.4,0,0,1-1.6,2.6,3.81,3.81,0,0,1-1.9.4h-.1v3.4l3,1.5v2.6c1.5,1.9,4.1,4.8,5.5,5.1l.5.1.2.4s2,3.7,5.4,4.3c1-.9,3-2.7,4.7-2.7a1.7,1.7,0,0,1,.7.1,2.34,2.34,0,0,1,1.5,1.6c.1,0,.3.1.4.1a.6.6,0,0,0,.4-.1c.5-.2,1.4-1,2-4l.2-.9.9.1h.4c1,0,3.6-.2,4.8-1.7l.2-.2.3-.1s.9-.4.1-2.5c-.4-1.2-.9-2.5.1-3.1a.9.9,0,0,1,.5-.1h.1l1.5-5.3,2.4-4.5c-.1-3.3.4-5.1,1.7-5.5.1,0,.3-.1.4-.1,1,0,1.9.9,3.1,3.1H584l1-7.3h2.7v-1.6l2.1-1.4c.3-1.9.9-4.1,2.1-4.6.1,0,.2-.1.3-.1l.9-4.2Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M594,181.3c-.6.2-1.8.8-2,1.4-.1.1-.1.2,0,.4l.3.7.5.1h0l.6.2.4.1h0l6.3,1.9.2-1.4-.3-1.8C597.65,182.1,595,181.2,594,181.3Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M504.55,209.8l-.6,4-5.8,5.9v4l-6.9-.5v-.6l-.2.2c.3,2,0,3.5-.9,4.3a2.79,2.79,0,0,1-1.8.6,5.47,5.47,0,0,1-1.2-.2l-.5-.2-.2-.5c-.6-1.7-1.2-2.1-1.4-2.1-.6,0-1.8,1.8-2.4,3.3l-.4,1-3.4-1.3-2.2-1.4-6,1.1-.2,1.8-.4.3c-.1.1-1.8,1.3-1.1,2.4a2.65,2.65,0,0,0,.5.6c.4.3,1.6,1.3,1.1,2.5-.3.8-1.3,1.2-2.9,1.2a2.79,2.79,0,0,1-1-.1,2.54,2.54,0,0,0-1.8.8c-.4.6,0,1.6.4,2.3l.1.3-.3,4.9-1.6-1.1a10.48,10.48,0,0,0-5.1-2c-.4,0-.7.1-1,.1a2.41,2.41,0,0,0-1.4,1.4l.3.3a4.13,4.13,0,0,1,.6,3.3l-.6,4.6H454v.1c6.7-.1,13.7-.3,15.7-.7v-3.7h1c.2,0,19-.7,22.5-1.7h12.6c.8-.1,10.7-1.8,15.3-1.8h1.1l.5-.1c4.6-.6,7.8-1,10.2-1.3l6.5-4.8v-4l8.6-8.5a21.87,21.87,0,0,1-5.4-5.5l-.2-.3v-2l-3-1.5v-5a14.63,14.63,0,0,1-2.7-1.1l-.6-.3.1-.7c.3-2.6-.3-3-.3-3h0a2.11,2.11,0,0,0-1,.4c-1.5,2.4-2.6,3.4-3.6,3.4a.9.9,0,0,1-.5-.1,1.68,1.68,0,0,1-1.1-1.4l-1.7.1q-1.65,1.8-3,1.8a1.7,1.7,0,0,1-.7-.1,2.68,2.68,0,0,1-1.5-2.3h-5.3l-.1-.9a3.43,3.43,0,0,0-1.5-2.8,5.33,5.33,0,0,0-3-.8,10.32,10.32,0,0,0-2,.2l.1,1.2a3.47,3.47,0,0,1-.1,3.3C509.85,209.1,506.35,209.7,504.55,209.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M402.55,112c1.6.6,5.6,2.2,7,4.7,1,.4,3.6,1.6,4.3,4l7.6,5.2,1,7c1.9,2.3,2.5,4,2,5.3a2.31,2.31,0,0,1-1.2,1.2l-.7,3.4,2.7,2.6q3.45.6,4.5,2.4v.1l9.3-.3,8.3-.4,8.8-.5,10.6-.4v-1.1c-3.5-4.9,0-15.5,0-15.5-3.1-4.9,6.2-28.3,6.2-28.3-8.8,14.7-11,13.3-11,13.3-1.4-.8.4-5.5,2.7-10.4l-4.1-3.5-.9-8.3c-2.6-.3-4.1-1-4.7-2.2a3.81,3.81,0,0,1-.3-1l-8.5-1.2-2.8-1.5-2.7-.5-7.3-1.7a5,5,0,0,1-4.2-2.9,26.39,26.39,0,0,1-5.3,0c3.5-5.7,1.3-5.4,1.3-5.4-6.5,3.7-10.7,5-13.4,5.3l-4.5,9.3-.7-.1h-.1a5.44,5.44,0,0,0-3.6,1.4,8.08,8.08,0,0,0-2.4,5.5,4.4,4.4,0,0,1,2.7,1.9c.7,1.2.6,2.7-.3,4.4C402.55,106,402,110,402.55,112Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M264.65,104.8l-.1,1.1c-.6,8-1.6,20.3-2.6,32.2l57.9,3.4h11.4l5.1,4.5-.1.5h.3a9.76,9.76,0,0,0,3.7-1.2l.3-.1h.3a12.25,12.25,0,0,0,1.9.2,3.28,3.28,0,0,0,1.8-.3l.6-1.2,1,.9s3.4,3.1,5.8,3.2h.4l.3.2a11,11,0,0,1,1.1,1h0l.1-.5c.7-2.3,1.7-6.5,1.6-8.1-.9-.6-2.8-2.3-2.1-6.3l.2-.9h1.9V103.5c-1.8-1.2-5.3-3.8-4.9-6.2.2-1.2,1.2-2.1,3-2.6V94L267,90.3C266.25,95.5,264.75,104.1,264.65,104.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M269,63.1c-.4,3.8-.8,7-.8,7.6v.9c-.1,3.1-.8,12.7-1.1,16.8l86.8,3.7a7.79,7.79,0,0,0-.5-7.5,6.78,6.78,0,0,0-.6-.9l-.3-.4.3-.2a.35.35,0,0,0-.1-.2c-1.9-4-1.9-11.9-1.9-12.2.4-7.6-.9-9-.9-9-2.2-1.5-2.2-9.9-2.1-13.3v-.1l.1-.3v-.1a5.88,5.88,0,0,0,.5-3.6c-20.8-.8-50.4-3.3-77.3-5.8C271.25,42.1,270.35,50.6,269,63.1Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M380.05,246.3l-1.3-36.6a6.18,6.18,0,0,1-3.7-2.5,8.15,8.15,0,0,1-.9-5.6l.1-.2c1.2-2.2,1.1-3,1.1-3.2h-.2c-.2,0-.5.1-.7.1a5.84,5.84,0,0,1-1.8.2,5.66,5.66,0,0,1-4.8-2.5l-85.1-2.2c-.6,6.6-1.2,13-1,15.2,1,5.8-1.3,29.2-1.9,34.7Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M357.35,160.5l-.1-.2-2.7-7.7a24.15,24.15,0,0,1-3.1-2.4c-2.3-.3-4.8-2.1-6-3.1a6.16,6.16,0,0,1-2.7.5c-.8,0-1.5-.1-2-.1a11.27,11.27,0,0,1-4.3,1.2,2.86,2.86,0,0,1-1.7-.5,1.71,1.71,0,0,1-.7-1.3l-3.6-3.2h-10.7l-58-3.4c-1.2,14.7-2.3,28.4-2.7,32.4l24.8,2.1.1.8c.3,1.9-.3,8.6-1.1,16.3v.2l84.1,2.2-1.1-3.8c-.7-.5-1.6-1.7-2.6-6.2l-1.5-10.3a10.2,10.2,0,0,0-2.2-6.8l-.4-.5.2-.6C359.85,164.5,358.45,161.9,357.35,160.5Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M257.05,172.5c.6-7.2,4.8-59,5.5-66.9l-82.3-10-1.1,8.7h.2L169.15,162C207.25,167,250.65,172.4,257.05,172.5Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M455.05,246c.2-.9.1-1.4-.1-1.6h0a5,5,0,0,1-1.6.3,3.29,3.29,0,0,1-1.9-.6c-2.2-1.5-2.2-5.2-2.2-5.6V238l.4-.3c.7-.6-.2-3.5-2-6.5l-4.1-.5-7.4-7.6.3-.7a17.76,17.76,0,0,0,2.1-9.9h-7.3l.3-1.3c.7-3.3,0-4.3-.2-4.6a.62.62,0,0,0-.4-.2h-.4l-.3-.3-6.6-6.4c-3.1-1.6-3.5-5.9-3.5-6.4v-.2a33.64,33.64,0,0,0,0-6.4,16.53,16.53,0,0,1-2.9-3l-51,1.7a17.85,17.85,0,0,0,1,2.6l.7-.1.1,1,1.6,5.5a3.55,3.55,0,0,0,3.2,1.8,4.87,4.87,0,0,0,1.2-.1,7,7,0,0,1,1.3-.2,2.48,2.48,0,0,1,1.8.7c.9,1,.7,2.7-.7,5.3a5.47,5.47,0,0,0,.6,3.9,4.8,4.8,0,0,0,3.1,1.8l.8.1,1.3,39h.1l-.1.5v1h-.1l-.7,6,60.9-1.6.2.1a3.33,3.33,0,0,1,2,2.5c.2,1.2-.4,2.4-1.6,3.7l-1,2,5.8-.3a12,12,0,0,0,2.5-8.6l-.2-1.2h2.1l-.1-2.2h2.7Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M424.55,198.1l.2.1,6.5,6.3a3.3,3.3,0,0,1,1.6.9q1.35,1.5.9,5.1h6.5l.2.8a19,19,0,0,1-1.8,11.5l5.9,6,4.3.5.3.4c.7,1.1,4.1,6.7,2.2,9.2.1,1.3.5,3,1.3,3.5a1.45,1.45,0,0,0,.7.2,2,2,0,0,0,.7-.1,4.76,4.76,0,0,1,4.5-3.4,10.08,10.08,0,0,1,4.8,1.5v-.7c-.7-1.6-.8-3-.2-4,1-1.7,3.5-1.8,3.6-1.9h1.3a4.06,4.06,0,0,1-.9-1c-1.1-1.8,0-3.7,1.3-4.9l.9-8.5,1,.2a17.78,17.78,0,0,0,2.3-5.7l.1-.5.5-.2c.7-.3.7-2.8.3-4.9-1.9-2.2-1.3-5.3-1.1-6.2l-1.3-10V182l-1.9-9.5.6-15.3c-2.3.8-2.8-5-2.9-8.8l-10.5.4-8.8.5-8.3.4-7.9.3a7.15,7.15,0,0,1,2.3,3.6c2,.9,3.2,2,3.7,3.4a3.89,3.89,0,0,1-.2,2.8c-.8,3.8-2.3,6.4-4.4,7.7a5.88,5.88,0,0,1-3,.9h-.7q-2.85.6-3.3,1.8a1.86,1.86,0,0,0,.2,1.3c1.6,1.8,2.3,3.6,2.1,5.3a4.88,4.88,0,0,1-1.9,3.1v4.3h-2.6c.2,1.5.2,3.2-1,3.7a1.27,1.27,0,0,1-.6.1,43.59,43.59,0,0,1-.1,5.7C422.15,194.2,422.65,197.2,424.55,198.1Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M632.05,140.9h0c-4.3-.4-5.8-4.2-6.2-6l-1.8.1-.1-1a1,1,0,0,0-.4-.7,2.65,2.65,0,0,0-1.4-.3,17.45,17.45,0,0,0-4.7,1l-7.8,1.6-10,1.7-22.1,4.3-11.1,3v-1.4c-1.9,1.6-4.6,3.5-6,4.5h0l1.3,7.9,1.8,13.8-.2.8,2.6,11.2,17.5-2.3,12.5-3.2,12-2,11.5-2.6,7.1-1.2.3.9a17.07,17.07,0,0,1,.5,1.8c1.6-2.3,4.2-5.4,5.2-5.8a26.49,26.49,0,0,0,3-3.8l.1-.1a6.47,6.47,0,0,0,1.5-1.7,2,2,0,0,0-.8-.1h-.3c-2.4-.9-3.8-2.1-4.4-3.5a2.62,2.62,0,0,1-.2-1.5h-1.8l-.3-.6c-1.5-3.3.2-4.5.4-4.7a2.74,2.74,0,0,0,.5-.4,1.74,1.74,0,0,0-.6-1.1l-.6-.6,1.8-2.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <polygon points="579.05 181.9 579.45 187.9 579.85 187.9 582.85 183.5 585.35 183.5 587.35 180.4 583.55 181.3 579.05 181.9" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></polygon>
              <UsaState d="M579.05,181.9l17.6-3.9,6,1.8s-20.3,1.6-22.5,4.1" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M591.05,180.5a2.51,2.51,0,0,1,.4-.7,2.34,2.34,0,0,1,.7-.7l-4,1Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M630,193.3v-1.1c0-1.3-2.3-9.4-4.3-16.3h-.1v-.3c-.3-1-.6-2-.9-2.9l-5.2.9L608,176.2l-11.6,2c2.5.6,6.1,2.9,6.6,3.2l.5.3v.2a4,4,0,0,1,1.6.6,5.8,5.8,0,0,1,2.3,3.8,7.12,7.12,0,0,1,5.3,2,5.61,5.61,0,0,1,1.1,5.3l-.1.4-.3.2c-.5.4-1.7,1.5-1.6,2.5.1.7,1,1.5,2.5,2.1l9.9,1.2c0-1.3.1-2.2.1-2.2-2.7-.5-2.8-5.7-2.8-5.7,1.5,1.1.2-7,.2-7-1.1-3.9,2.2-4.8,2.2-4.8,4.8-4.8.4,4.4.4,4.4,2,.4,2,5.2,2,5.2l-1.7.2.7,1.3c4.4,1.7,0,3,0,3,1.1,5,3.7,4.4,3.7,4.4,6.5-.7,5.4,3.7,5.4,3.7,3.7.7,1.3,4.1,1.3,4.1-2.2,0-1.1,10.9-1.1,10.9,3.7,2.8,3-7.6,3-7.6A9.46,9.46,0,0,1,642,201l.2-8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M177.25,104l-.1-.3c-1-4-2.1-4.5-2.5-4.6h-.1c-1,0-2.5,1.7-3.2,2.9l-.7,1.1-1-.9a7,7,0,0,0-4.6-1.5H165a11,11,0,0,1-2.6.2,27.88,27.88,0,0,1-4.1-.4c-1.2,1.4-2.1,2-2.9,2a1.27,1.27,0,0,1-.6-.1c-.8-.3-1.1-1.2-.9-2.4.2-4.9-.8-5.7-.9-5.8l-.2.1-.2.1-.4-.2c-3.5-1.6-5.2-10.1-5.6-11.8V82c.9-3.9.3-4.5.3-4.5-.7,0-2.5,1.4-4,3l-.5.5-.7-.3a3.85,3.85,0,0,1-2.2-2.3c-1.7-4.6,2.6-13.8,3.1-14.8l.1-.2.2-.2c1-.7,1.5-1.4,1.5-1.9-.1-.9-2-2-3.2-2.4l-.2-.1c-2.9-1.8-5.3-8.5-6.1-10.9-2.6-1.1-3.8-4.8-3.8-5l-.1-.3.1-.3c2-6.7-1-8.3-1.1-8.3l-.9-.4.4-.9c.4-.9,1.9-9,3-15.2q-4.65-1.05-9-2.1c-5.5,20.9-10.7,43.5-10,45.6l.1.2a9.44,9.44,0,0,0,.2,1.8h0v.1c.3,1.7,1,4.1,2.5,4.8l.3.1.2.3c.9,1.3,1.8,4.4-.8,6.3-.6.7-3.7,4.9-4.9,6.4a5,5,0,0,1-.7,3.2,2.87,2.87,0,0,1-2,1.1c-.7.5-5,3.5-3.7,5.7l.6,1.1-1.2.4a14,14,0,0,0,2.6,1l1,.3-9.7,36c8.1,1.9,61.1,14,72.2,14.3Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M532.85,259.7h0l.4-.2h.1c3.3-.6,4.2-2.7,4.2-2.8l.2-.5,4.1-1.3v-3l5.3-4.3.2,1.9a2.39,2.39,0,0,0,.3,1.1c.6-2,1.6-3.2,2.9-3.6a2.77,2.77,0,0,1,.9-.1,4,4,0,0,1,1.5.3c1-2.8,2.1-4.4,3.5-5,.1,0,.2-.1.3-.1,0-2.4-.5-2.7-.5-2.7a5.91,5.91,0,0,0-1.5,1.3l-.3.3-15.4,1.8c-.3,0-2,.3-5.1.6l-.2.2-.1-.1c-2.6.3-6.1.8-10.5,1.3l-.6.1h-.1c-.3,0-.7-.1-1.1-.1-4.4,0-14.9,1.8-15,1.8H494c-3.6,1-17.8,1.6-21.8,1.7v2.8l-.3.3c-.3.3-1.3,1.3-19.3,1.4a14.55,14.55,0,0,1-3.2,9.2l-.9,3.2-.2,5-2.9,1.2a12.76,12.76,0,0,1,.9,5.4h24.5l4.5-1.3,18.4-1.5,14.4-1.6,16.6-1.8.3-3.7.5-.3c2.7-1.3,3.2-2.3,3.2-2.5v-.2l-.1-.1C527.85,263.2,528.05,262.9,532.85,259.7Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M638.05,84.3h.7l.1.9a17,17,0,0,1,0,6.1c.6,2.4,1.5,4.1,1.9,4.3l1.2.4-.5,1.1a2.9,2.9,0,0,0-.2,2.1,2.61,2.61,0,0,0,1.8,1l.7.1,3.1,11.4,5.6-1.2v-3.1l1-3.3-1.1-1.7,1.2-10.5V82.8l.4-.3c1.3-1.1,3.8-3.8,3.7-5.7a1.62,1.62,0,0,0-.7-1.4l-.3-.2-.2-1.1L637.05,78h-.3A24,24,0,0,0,638.05,84.3Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M96.45,131.6h0c-3.7-.8-18.6-4.1-35.3-8.1l-12.5,49.8,53.4,79.7c.6-2.1,1.6-6.5,3.6-15.9l.4-1.8,1.4,1.2c2.6,2.4,5.1,3.9,5.9,3.9h0s.4-.8-1.1-4.6l-.1-.3,20.5-95.9C115,135.9,97.75,132,96.45,131.6Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M382.55,301.5V271.3l-3.3-16.6.7-6.4L278.75,246a31.12,31.12,0,0,1-3.2.1c-2.4,0-5.3-.1-8.4-.3l-.4,5.8,41.3,1.3v1c-.3,13.3-.8,29.7-1.5,33.4,1.6,2.1,3.5,3.8,4.3,3.8.2,0,.3-.3.4-.5l.1-.2a1.84,1.84,0,0,1,1.4-.9.6.6,0,0,1,.4.1,1.39,1.39,0,0,1,.8.7v-.6l.4-.3a2,2,0,0,1,1.3-.5,1.45,1.45,0,0,1,.7.2c1.1.5,1.7,2,1.8,4.8,4.8.3,7.8,1,9.2,2.1v-.1l.2-.3a1.94,1.94,0,0,1,1.5-1h.3a1.74,1.74,0,0,1,1.1,1.5l2.6-.2-.4,1.5s-.1.4.1.6a1.8,1.8,0,0,0,1.3.4h1V300l-.2.3a.6.6,0,0,0-.1.4,2.41,2.41,0,0,0,1.5.3.93.93,0,0,0,1-1v-1.4l4.1,1.2.2.2c1.6,1.7,4,3.5,5.1,3.5h.3c.3-.1.7-.9.7-2.5v-.3l.2-.3a2.27,2.27,0,0,1,1.8-1.2.6.6,0,0,1,.4.1,2.37,2.37,0,0,1,1.3,2.2h2.9l.3.2a7.24,7.24,0,0,0,3.3,1.1,4,4,0,0,0,2.5-1l.2-.2,5-.8a2.88,2.88,0,0,1,1.7-1.2c.2,0,.3-.1.5-.1a3.66,3.66,0,0,1,2.5,1.2h1.4a2.15,2.15,0,0,1,1.1-2.1,2.39,2.39,0,0,1,.9-.2c1.1,0,2.4.8,4.2,2.6,1.6,2.2,3.5,4,4.3,4a.22.22,0,0,0,.2-.2v-.1h.1C382.35,303.9,382.55,303.1,382.55,301.5Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M446.15,265.2l.6-2.2-8.5.5,3-5.6.1-.1c.8-.7,1.2-1.4,1.1-1.9a1.6,1.6,0,0,0-.7-.9l-60.1,1.6,2.9,14.6v30.4a16.59,16.59,0,0,1-.2,2.4,17.91,17.91,0,0,1,2.4-.2,5.84,5.84,0,0,1,3.5.9,2.71,2.71,0,0,1,1.1,2.3c-.1,2.6-.2,5.2-.2,7.7,2.5-.1,11.2-.5,20-.7,3.9-.1,7.2-.1,10-.1a68.48,68.48,0,0,1,9.7.4v-3.6c-.3-1-1.9-7.3-1.1-12l.1-.7.6-.2a10.8,10.8,0,0,0,3.3-1.4c-.1,0-.1-.1-.3-.2l-.6-.3v-.7c0-.4.3-4.2,2.5-5.4a3.26,3.26,0,0,1,1.4-.4,2.77,2.77,0,0,1,.9.1,2.31,2.31,0,0,0-.3-2.6l-.3-.3v-.4c0-1,.1-4.1,1.8-4.8a1.7,1.7,0,0,1,.7-.1,2.65,2.65,0,0,1,1.4.5l-.6-4.5h3c.4-1.7-.6-4.5-1.1-5.6l-.5-1,3.7-1.5Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M609.85,281.2c0-.1.1-.1.1-.2-3.3-3.1-13.7-10-20.3-14.1a8,8,0,0,0-1.1-.1,82.42,82.42,0,0,0-13.1,1.9l-.9.2-.3-.9c-.7-2.2-2.6-3.3-5.5-3.3a9,9,0,0,0-3,.4h-.2l-12.2.1-.2-.1c-1.3,0-6,2.4-10.6,5.3-.3.3-2.4,2.2-2,3.8.4,1.3,2.6,2.4,6.4,3l.5.1,3,5.4c.1.1,3.1,4.5,6,4.6h.7l.3.6a9.27,9.27,0,0,0,5.2,4.7l.6.2.2.6a8.69,8.69,0,0,0,5.9,5.6l.3.1.2.2a17,17,0,0,1,3.6,7.1v.3l2.4.8h.1a7.85,7.85,0,0,1,3.1,7.5h7.4c3.8-4,1.7-6.1,1.7-6.1h2.4c-.4-1.7,3.9-3.5,3.9-3.5,0-2,3.7-3.7,3.7-3.7.4-4.8,4.4-3.9,4.4-3.9-.4-4.6,1.1-4.6,1.1-4.6C602.25,287.9,609.85,281.2,609.85,281.2Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M526.25,271.7l-.1,1-17.7,2,9.8,34.7,3.2,6.2v2h.3a1.66,1.66,0,0,1,1.2.5c1.1,1.1.1,3.2-1.1,5.2-.3,2.7-.5,6.9.4,7.9l.2.3.1,4.7h0a18.24,18.24,0,0,0,1.9,5.1l2.8,3.1,39.4-2v3.2h.8l.3-2.8c-.9-1-2.2-2.7-1.5-4.1q.6-1.2,2.7-1.2a4.87,4.87,0,0,1,1.2.1l7.7-.1a13.88,13.88,0,0,1,.8-5.8,45,45,0,0,1,4.8-14.6l.1-.1h-7.7l.3-1.3c.9-4.1-1.4-6.1-1.9-6.5l-3.4-1.1v-.7a2.2,2.2,0,0,0-.1-.8c-.4-2.5-2.4-5.1-2.9-5.9a10.56,10.56,0,0,1-6.8-6.4,10.76,10.76,0,0,1-5.7-5.1c-3.8-.6-7-5.3-7.1-5.5l-2.5-4.6c-4.4-.8-7-2.2-7.6-4.4a4.9,4.9,0,0,1,.9-3.9Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M658.65,73.7l.1.4a3.57,3.57,0,0,1,1.2,2.7c0,2.9-2.9,5.9-4.1,7v8.3l-1.1,9.8,1.2,1.9-1.2,4v2.3l14.4-2v-1.3c1.5-3.7,6-4.3,8.8-4.3a13.77,13.77,0,0,0,.6-1.6h-6.1V98.6a2.2,2.2,0,0,1-1.3-.4c-2.3-1.3-2.8-6-2.9-7.5l-3.6-12.4-3.6-11.7c-2.7.6-1.8,6.9-1.8,6.9Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M611.15,409.2c.9-5.4-11.8-23.3-11.8-23.3-3.9-7.4-1.3-8.7-1.3-8.7a42.6,42.6,0,0,0-5-5.4c-4.8-2.6-12-21.3-12-21.3a43,43,0,0,1-2.8-10.8l-7.9.1a4.91,4.91,0,0,0-1.1-.1,3.78,3.78,0,0,0-.8.1,5.69,5.69,0,0,0,1.4,2.1l.4.4-.6,5.7h-4.8v-3.1l-38.2,1.9-3.2-3.5-36.7,3.3v1.3l3.1,2.2.3,9.5c1.1-.2,1.9-.4,1.9-.4,7.4-3,16.8-2.2,16.8-2.2,2.8,0,9.1,3.3,9.1,3.3,9.1,3.5,6.3,7,6.3,7,8.5-.4,11.5-5.9,11.5-5.9,1.5-4.6,6.5-4.1,6.5-4.1,0,1.1,4.6,1.7,4.6,1.7,2.6,3.5,5.2,3.9,5.2,3.9a5.37,5.37,0,0,0,3,3.7c1.5.2,1.5,3.7,1.5,3.7.9-1.5,5.4.4,5.4.4,1.1,3.3,2.4,3,2.4,3,3,2.6.2,11.5.2,11.5a11.58,11.58,0,0,0,3.3,10.2c1.5-2.2-.9-3.9-.9-5s5.7,1.5,5.7,1.5c1.3,1.1-4.8,6.5-4.8,6.5v.9c0,.9,8.5,10,8.5,10,5.2-8.9,3.3,4.1,3.3,4.1,4.6,1.7,4.1,5.7,4.1,5.7,4.1,6.5,5,3.5,5,3.5,3.5-1.3,7.6,7,7.6,7-.4,5.7,2.6,5.4,2.6,5.4.4-2.6,3.7-1.3,3.7-1.3,4.1-.9,6.8-4.8,6.8-4.8-1.3-5.2,2.2-7.4,2.2-7.4C610.25,419.7,611.15,409.2,611.15,409.2Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M206.55,324.8a3,3,0,0,1,2.4-.9,11.18,11.18,0,0,1,3.5.8h0l.2.2h0c1.5,1.3,25.1,2.6,46.3,3.2l6-82.5c-26.5-1.8-72.3-8.6-80.1-9.8l-13.1,98.3,12.9,2,1.7-8.5,20.3,2.4C205.65,327.5,205.55,325.9,206.55,324.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M470.65,331.9l.2-18.5,1.1-19.7V280.2l-1.5-1h-.1v-.1h-27.2l.3,2.3c1.3,2.6,1.1,3.4.4,3.9a1.45,1.45,0,0,1-.7.2c-.7,0-1.3-.6-2.3-1.6a2,2,0,0,0-.9-.7,5.88,5.88,0,0,0-.4,2.4c1.7,2,.6,4.6-.2,5.7l-.5.7-.8-.4a1.6,1.6,0,0,0-.8-.3.6.6,0,0,0-.4.1c-.7.4-1.2,1.8-1.4,3.1a1.76,1.76,0,0,1,.8,1.8c-.2,1.5-2.4,2.5-4.3,3.1-.6,4.5,1.2,10.5,1.2,10.6v6.1l-1.1.1c.5,3.9,1.4,6.8,1.9,7l.5.2,1.8,5.4h-1.9l.4,1.9-.4.4a27.69,27.69,0,0,0-6.3,17.6l26.5-1.3-.2,1.3c-.4,2.4-.7,7.4,1.5,8.1l.4.1,3.3,7a1.24,1.24,0,0,0,.3-.2c3.9-6.8,7-3.5,7-3.5,4.6-2.8,5.2.4,5.2.4l1.6-.2C473.85,355.6,472.05,341,470.65,331.9Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M393.85,375.2l.3-6.8.1-.2c2.2-3.6.4-5.8-.2-6.4a1.49,1.49,0,0,1-1.2-1.2c-.2-1.1.9-1.9,2.1-2.5,1.7-.9,2.3-5.6,2.3-7.7v-.2c1-4.2-3.4-8.3-3.4-8.3l-.9-.9.8-.6a12.27,12.27,0,0,0-1-3c-3.9-1.4-3.6-7.3-3.4-8.7-.6-2.8-.1-18.7,0-21.9a.71.71,0,0,0-.3-.6,3.89,3.89,0,0,0-2.1-.4,26.24,26.24,0,0,0-3.5.4,1.93,1.93,0,0,1-.9.5,1.7,1.7,0,0,1-.7.1c-2.4,0-5.1-3.6-5.9-4.7a7.82,7.82,0,0,0-2.4-1.9,3.88,3.88,0,0,0,0,1.1l.2,1.2h-4.7l-.3-.3c-.8-.8-1.2-.9-1.4-.9a1.58,1.58,0,0,0-.6.5l-.2.6-5.5.8a5.43,5.43,0,0,1-3.6,1.4,9.08,9.08,0,0,1-4.3-1.3l-4.1-.1a3.09,3.09,0,0,1-1.6,1.9,3,3,0,0,1-1,.2c-2.4,0-5.5-2.9-6.5-3.9l-1.1-.3a2.92,2.92,0,0,1-2.8,1.7c-1.7,0-2.9-.5-3.4-1.4a2,2,0,0,1-.2-1.3,3.09,3.09,0,0,1-1.6-1.1,1.78,1.78,0,0,1-.4-1l-1.4.1c-.2.6-.5,1.1-1.1,1.2h-.2c-.7,0-1.3-.6-1.8-1.7v-.3c-.5-.4-3.9-1.4-9-1.6h-1v-1a17.47,17.47,0,0,0-.2-2.9,1.76,1.76,0,0,1-1,1.2,2,2,0,0,1-2.2-.3l-.2-.2a1.53,1.53,0,0,1-1.1.5H311c-2.4,0-5.4-3.8-6.3-5l-.5-.7.5-.5c.4-1.6,1-17.3,1.4-32l-39.3-1.2-5.5,76.6h-1c-7.5-.2-44.7-1.2-48.5-3.6a8.31,8.31,0,0,0-2.6-.6,1.29,1.29,0,0,0-.8.2s-.6.7,1.1,4h0l1.1.1c4.8,1.5,5,6.5,5,6.5,5.9,2.2,9.8,10,9.8,10,8.5,5.7,10,12.4,10,12.4-2,13.5,5.9,15,5.9,15a23.62,23.62,0,0,1,10.2,7.4c4.6,5.2,7.4-.9,7.4-.9,4.4-7.8,5.7-8.9,5.7-8.9,1.7,0,5-3.3,5-3.3,5.9,3.7,14.4,1.7,14.4,1.7l-.4.9c-.4.9,11.1,12,11.1,12-1.3,2.6,2.8,9.4,2.8,9.4,1.3,7.8,3.5,5.9,3.5,5.9-.4,2,6.8,10.5,6.8,10.5,5.2,1.1,3,6.1,3,6.1,2.2,1.3.4,5.7.4,5.7a4.31,4.31,0,0,1,3.9,5c.2,7,10.7,7.8,10.7,7.8,3.5,3.9,6.3,3,6.3,3,7.4-.4,7.2,3,7.2,3,3.7,3,5.7-1.3,5.7-1.3-9.8-22,6.1-35.1,6.1-35.1,1.3.2,9.1-6.3,9.1-6.3,1.5-2,14.4-7,14.4-7,3-4.6,9.1-7.8,9.1-7.8l-.7-2A68.84,68.84,0,0,1,393.85,375.2Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M460.25,376.6c-3.3-.9-.9-4.8-.9-4.8,1.1,0,3.7-1.5,3.7-1.5,1.1-3.7-2.2-3.9-2.2-3.9-1.3,5.7-4.4,3.3-4.4,3.3-2.6-1.4-.7-3.3,1.2-4.6l-3-6.4c-3.1-1.4-3-6.3-2.7-9L426,351l-.1-1a29.69,29.69,0,0,1,6.4-19.7l-.8-3.4h1.6l-.6-1.8c-1.6-1.3-2.4-5.8-2.8-8.9-1.3-.1-3.8-.3-8.4-.3-9,0-21.8.5-30.3.9-.1,6-.1,10.9.2,11.7l.1.2v.2c0,.1-.7,6.1,2.3,6.8l.5.1.2.4c1.1,2.2,1.6,3.8,1.4,4.8,1.3,1.4,4.5,5.3,3.6,9.6,0,1.4-.3,7.7-3.4,9.4-.2.1-.3.2-.5.3.9.9,3.5,4,.8,8.8l-.2,5.5c7.6-2,11.9-.7,11.9-.7,3.3,3.3,15,3,15,3-2.2-8.1,3.3-3,3.3-3,5-2.8,5.7,2.4,5.7,2.4,6.5-.2,5,5.7,5,5.7,4.1,3.5,7.2,1.7,7.2,1.7,0-3,3.5-2.8,3.5-2.8,1.5-2.4,3,.4,3,.4,3.9,3.3,2.6-3.9,2.6-3.9l2.8-1.5a6.62,6.62,0,0,1,2.2,2.6c4.1-.4,6.8,3.7,6.8,3.7,5.9.7,2.6-2.6,2.6-2.6C467,377.4,460.25,376.6,460.25,376.6Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M374.75,135.8l-18.2-.2h-1.2c-.2,2.8,1.4,3.4,1.4,3.4l.5.2.2.5c.7,1.9-.7,6.9-1.3,9.3,1.4,2,1.8,3.2,1,3.9l-.1.1,2.2,6.3c.8,1,2.8,4,2.5,6.5a12.51,12.51,0,0,1,2.4,7.7l1.5,10.1v.1l52.5-1.8.3.4a17.18,17.18,0,0,0,3.4,3.4,10.76,10.76,0,0,0-.3-2.5l-.3-1.3h3.1v-3.4l.5-.3a3.11,3.11,0,0,0,1.4-2c.2-1.1-.4-2.3-1.6-3.6a3.45,3.45,0,0,1-.5-3.3c.6-1.5,2.3-2.6,5.1-3.1h.2l.2.1h.4a3.64,3.64,0,0,0,1.9-.6c1.6-1,2.8-3.2,3.4-6.4l.1-.3a1.24,1.24,0,0,0,.1-1.3c-.3-.9-1.4-1.7-3-2.3l-.6-.2-.1-.6c0-.1-.5-3.1-2.9-3.5l-1.3-.2.6-1.2a1,1,0,0,0-.1-.7c-.2-.4-.9-1.1-3.3-1.4h-.3l-4.1-3.9,1.2-5.9.7-.1s.2-.1.2-.2.3-.8-1.6-3.1h-16.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M685.85,122.8c-3.5-7-5.7-6.1-5.7-6.1-5.4,1.5-1.7-7-1.7-7a6.11,6.11,0,0,1-1.3-5.2c-2.2.1-5,.7-6,2.8v2.5l-17.4,2.4-6.5,1.4-.1,10.6,25.2-5.9.4.5c.6.8,5.6,7.2,8.2,10h.1c2.2-7.4,4.8-1.5,4.8-1.5.7-2.6,7.6-3.3,7.6-3.3l-.7-2.8C686.55,126.3,685.85,122.8,685.85,122.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M641,237.8c-3.5,2.6-3,0-3,0-.4-.9-3.3,1.5-3.3,1.5-12.2,3.7-.9-3.5-.9-3.5,3.9-2.6,4.8-1.5,4.8-1.5,4.6,2.8,3.5.9,3.5.9l-4.2-8.2c-12.8,4.8-70.4,12.8-79.2,14a9.6,9.6,0,0,1,0,2.4l-.1,1.7-1.4-.8H557c-.3.1-1.4.9-2.6,4.9l-.5,1.6-1.2-1.1a2.59,2.59,0,0,0-1.4-.6H551c-.7.2-1.3,1.2-1.7,2.7l-.1.4-.4.2a2.2,2.2,0,0,1-1.2.3,1.69,1.69,0,0,1-1-.3,2.17,2.17,0,0,1-1-1.1l-1.7,1.4v3.5l-4.7,1.5a7.41,7.41,0,0,1-5.4,3.5c-1.4,1-2.8,1.9-3.4,2.4v.9c-.3,1.3-1.6,2.5-3.8,3.7l-.1,1.1,15-1.1c2.8-1.8,9-5.5,11.7-5.5a1.27,1.27,0,0,1,.6.1l11.6-.1a16.23,16.23,0,0,1,3.4-.4c3.5,0,6,1.3,7.2,3.8,2.5-.5,9-1.7,12.5-1.7a5.84,5.84,0,0,1,2.1.3h0c.2.1,16.5,10.2,21.1,14.8a28.19,28.19,0,0,1,6.8-1c-.9-5.4,4.1-9.6,4.1-9.6,4.4-7.2,13.1-7.8,13.1-7.8,5.4-3.5-2-5-2-5,2-2.8-.7-6.8-.7-6.8.9,1.1,6.1,0,6.1,0,1.5-5.2,3.3-5.4,3.3-5.4C645.55,242,641,237.8,641,237.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M710,55.4c-4,3.1-4,.4-4,.4-.9-9.7-7.1-6.6-7.1-6.6l-6.2-22.5-8-4c1.8,2.2-4.4,4.9-4.4,4.9-6.6-.9-2.7-4.4-4.9-3.5s-5.7,15-5.7,15c2.2,4,.4,6.2.4,6.2-2.7,0-2.7,5.3-2.7,5.3,8.4,2.7,4,3.5,4,3.5-5.3,2.2-5.3,13.3-5.3,13.3h-2.7c-.1,0-.1-.1-.2-.1l3.3,10.4,3.7,12.7v.1c.1,2,.8,5.3,1.8,6,.1,0,.2.1.3.1a1.09,1.09,0,0,0,.5-.1l1.5-.8v3.2h4.5a97,97,0,0,0,1.7-11.1,7,7,0,0,0,6.6-2.2c-.4-2.7,4-3.8,4-3.8.9-14.4,4.4-7.3,4.4-7.3,5.7-5.7,7.5-3.5,7.5-3.5l6.6-5.3C715.25,59,710,55.4,710,55.4Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M633.55,180.5a6.7,6.7,0,0,1-2.2-3.5l-2.9-2.6c-.3.5-.6.9-.7,1.1,1.5,5,3.6,12.6,4.2,15.6l9.4-.2-.5-1.5C638,189.3,633.55,180.5,633.55,180.5Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M647,154.4l2.3-6.9-2.7-1.5c-4.6-.7-11.6-3.8-11.9-4l-.7-.3-1.7,5.4-1.1,1.6a3,3,0,0,1,.6,2.3,2.81,2.81,0,0,1-1.3,1.8c-.1.2-.2.6.1,1.5h3.5l-.8,1.5a1.65,1.65,0,0,0,0,1.3,5,5,0,0,0,2.9,2.2,2.73,2.73,0,0,1,2.6,1.5c.5,1.4-.9,2.9-2,3.9-2.5,3.5-3.4,4.3-4,4.4a55,55,0,0,0-4,4.7l2.3,2c6,4.9,10.3,4.7,10.3,4.7l.7,4.6c1.7-.4,2.4-5.9,2.4-5.9,3.7-2.4,4.6-12.6,4.6-12.6l-.9-9.4C642.35,156.6,646.35,154.7,647,154.4Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M648.35,144.1h0v.1l2.2,1.3a29.36,29.36,0,0,1,13.7-8.2c.4.2,1.8-.2,3.4-.8v-.1c.9-3-.8-11.4-1.4-14.3l-19,4.5C647.75,129.4,649.35,139.6,648.35,144.1Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M671.55,120.8l-3.2.8c.6,2.8,2,10,1.6,14,2.1-.8,3.9-1.6,3.9-1.6a10,10,0,0,1,2.2-2.8c.7.5,2.1-.2,3.2-.9C676.85,127.5,672.85,122.4,671.55,120.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M488,359.8l-.3-8.9-3.2-2.2v-4.3l37.6-3.3a24.53,24.53,0,0,1-1.4-4.6l-.1-4.2c-1.5-2.3-.8-8.3-.6-9.4v-.1l.1-.3h0a14.77,14.77,0,0,0,1.3-2.6c-.1,0-.2.1-.4.2l-1.6.9v-4.8l-3.1-6-10-35.2-12.9,1.4-18.2,1.5-2.2.6,1,.6v14.7l-1.1,19.7-.2,18.3c0,.2,3.2,20.9,2.9,28.5h.2V359l4.1-.2c1.7-11.1,5.7-2.2,5.7-2.2.7,1.5-.3,3.8-.6,4.4A11.42,11.42,0,0,1,488,359.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M527.55,159.7l-3.5-3.5a5.28,5.28,0,0,1,.1-1.3l-13.9,2.7-4.1.6-.4,4.3,1.6,6,1.3,8.7.6,10.3,1.3,9.3.3,3.5a12.73,12.73,0,0,1,2.2-.2,7.7,7.7,0,0,1,4.2,1.1,5.39,5.39,0,0,1,2.2,3.4h5.4v1a3.66,3.66,0,0,0,.3,1.4s.5-.1,1.7-1.4l.3-.3,4.8-.2-.1,1a19.4,19.4,0,0,0,1.4-2l.2-.2a4.55,4.55,0,0,1,2.5-1,2,2,0,0,1,1.1.3c1.1.6,1.6,2,1.5,4.4a7.74,7.74,0,0,0,3.2.9,2,2,0,0,0,.9-.2c.2-.1.6-.3.6-1.6v-.8l1.7-.5c-.2-2.8-.2-7,1.7-7.8a1.88,1.88,0,0,1,.8-.2,3.94,3.94,0,0,1,2.7,1.6,5.31,5.31,0,0,0-.4-1.9,2.73,2.73,0,0,1-.5-1.6c0-1.3.8-2.7,2.3-4.2a2,2,0,0,1,1-1.9,1.48,1.48,0,0,1,.7-.1,3.46,3.46,0,0,1,2,.9,42.55,42.55,0,0,0,4.3-6.1l.1-9.5,1.4-4.3-.1-.3h.2l.2-.6-1.7-13.3-1.2-7.5c-6.5.5-10.9,9.3-10.9,9.3-3.5-.9-13,3.5-13,3.5l-2.1-2.7Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M533.25,133.6c-2.2-2.2-4-11.9-4-11.9.4-8.4-7.1-4-7.1-4-4.9,8.4-7.1,8-7.1,8-5.7-7.1,1.3-8,1.3-8l1.3-4.4c3.1-3.1,0-8.4,0-8.4a4.15,4.15,0,0,1-1.8-4c4-1.8,0-6.2,0-6.2-1.3.4-5.3,0-5.3,0-6.6-5.7-7.1-2.2-7.1-2.2-7.1-6.2-6.2,1.8-6.2,1.8,4.9,1.8-3.5,4.9-3.5,4.9,1.3,7.1-3.1,8.4-3.1,8.4v-8l-1.8,3.5c-5.7,1.8-2.7,5.3-2.7,5.3-4-1.3-2.2,3.5-2.2,3.5a35.51,35.51,0,0,0-1.8,16.3c10.7,23,2.2,26.9,2.2,26.9-.4.6-.7,1.1-1,1.6a29.74,29.74,0,0,0,5.6-.6h5.4l4.5-.7,6.1.7,4.7-.7,8.2-1.6,6.9-1.3a3.91,3.91,0,0,1,2.1-1.7c-2.2-4.4,2.7-8,2.7-8,2.2-7.5,3.5-1.8,3.5-1.8C536,140.3,533.25,133.6,533.25,133.6Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M482.05,86.7c2.2,1.2,5.3,0,5.3,0,3.1-5.4,6.2-1.2,6.2-1.2a8,8,0,0,0,8.4,0c.9-2.2,6.6,0,6.6,0l-4-3.8v-5c-7.5,3.1-10.6.9-10.6.9V73.2l-4.9,2.7c-7.1-3.5-14.6,5.9-14.6,5.9-4.4-6-8.4,0-8.4,0-1.8-8.2-7.5-7.7-7.5-7.7-9.3,4.4-6.2,1.8-6.2,1.8-.4-5.3,8-11.5,8-11.5-2.2-.4-7.5,2.2-7.5,2.2-7.1,10.6-15.5,10.2-15.5,10.2-.6,2.7-3.1,3.9-5.8,4.5a2.66,2.66,0,0,0,2.2,1.2h.1l7.4,1.7,3.1.5,2.7,1.5,10.8,1.5-.8,1.3h0a.48.48,0,0,0,0,.4c.1.2.6.9,3.9,1.1l.9.1,1,9.2,3,2.6c2.7-5.3,5.6-10.3,5.6-10.3,7.5-3.1,8.4-.4,8.4-.4C483.25,93.8,482.05,86.7,482.05,86.7Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M558.15,238.9c7.8-1.1,66.1-9.1,78.7-13.8l-1.3-2.6c-3-1.3-5,3.3-5,3.3-2.2-.2-7.4-7.4-7.4-7.4-.7-4.3,4.8,3,4.8,3,3.5,2.4,3.5,0,3.5,0-7.2-7.8-1.5-6.5-1.5-6.5a15.45,15.45,0,0,1-1.1-7.8c-2.8-.6-4-2.9-4.4-5.1l-10.7-1.3h-.1c-2.4-.9-3.7-2.2-4-3.8-.3-1.9,1.4-3.6,2.1-4.2a3.42,3.42,0,0,0-.7-3.1c-1.1-1.2-3.1-1.3-3.9-1.3h-.6l-1,.1-.1-1a4.86,4.86,0,0,0-1.5-3.3c-.2-.1-.3-.2-.5-.2V185l-1.4,3.7-6.9-3.3-1,4.8-1.1-.9a14.28,14.28,0,0,0-.8,3.2l-.1.5-2,1.3v2.6h-3l-1,7.3h-4.6l-.3-.6a17.62,17.62,0,0,0-1.3-2.2,13.81,13.81,0,0,0-.2,3.3v.3l-2.6,4.9-2.1,7.3a4.35,4.35,0,0,1-.1,3.4,3.07,3.07,0,0,1-1.2,1.3c-1.7,1.9-4.5,2.2-5.9,2.3-.7,2.5-1.7,4.1-3.1,4.6a5,5,0,0,1-1.2.2,3.34,3.34,0,0,1-1.9-.6l-.4-.3-.1-.4a1.19,1.19,0,0,0-.2-.5c-.7,0-2.4,1.1-3.6,2.4l-.4.4-.5-.1a10.25,10.25,0,0,1-6.8-4.3l-8.2,8.1v4.1l-4.4,3.2c.9-.1,1.3-.2,1.4-.2l14.6-1.7c.6-.7,1.7-1.8,2.9-1.8a1.7,1.7,0,0,1,.7.1A3.11,3.11,0,0,1,558.15,238.9Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M275.55,244h2.3c.5-4.6,3-28.8,2-34.5-.3-2.8.5-10.6,1.2-18,.6-6.1,1.2-12.4,1.2-14.9l-24.1-2h-.7c-6,0-37.3-3.9-63.3-7.2l-8.8,66.4C194.05,235.1,253.55,244,275.55,244Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M131.75,32.7c1.3,1.1,3.3,3.8,1.5,10,.5,1.4,1.6,3.1,2.8,3.5l.6.1.2.5c.7,2.3,3.1,8.7,5.2,10.1,1,.4,4.1,1.7,4.5,4,.2,1.3-.5,2.6-2.1,3.7-1.6,3.5-4,10-2.9,12.9a2.18,2.18,0,0,0,.5.8c1.3-1.3,3.3-3,5-3a3,3,0,0,1,1,.2c1.4.7,1.6,2.8.8,6.6.6,3.3,2.3,8.6,4.1,9.9h.2a2.66,2.66,0,0,1,1.2.3c1.4.9,2,3.3,1.8,7.5a14.25,14.25,0,0,0,1.1-1.2l.4-.5.7.2a19.9,19.9,0,0,0,4.3.5,19.42,19.42,0,0,0,2.4-.2h.4a9.62,9.62,0,0,1,5,1.4c.9-1.3,2.5-3,4.4-3h.4a3.69,3.69,0,0,1,2.7,2.1l.8-5.9,84.4,10.3c.5-2.9,1.7-10.2,2.1-14.3v-.1c.2-3.3,1-14.3,1.1-17.7v-.8c0-.7.3-3.5.8-7.8.8-7.4,2.3-20.4,2.3-24.7-34.5-3.3-64.1-6.6-64.1-6.6-23.7-3.7-48.3-8.7-70.3-13.6C134.05,21.5,132.55,29.9,131.75,32.7Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M96,129.4l9.2-34.1c-2.4-.8-3.5-1.6-3.5-2.7a1.87,1.87,0,0,1,.8-1.4c-1-3.9,4.8-7.5,5.1-7.7l.2-.1h.3a.75.75,0,0,0,.7-.3,3.12,3.12,0,0,0,.3-2.1l-.1-.5.4-.4c.6-.6,3.4-4.3,5.2-6.7l.2-.2a2.61,2.61,0,0,0,.6-3.4c-1.7-1-2.6-3.1-3.1-4.9L96,61.7l-.2-.5c-.2-.5-2.6-1.2-7-1.2h-2.1l-.3-.2c-.1-.1-.8-.5-5.6-.5-1.3,0-2.7,0-4,.1a12.31,12.31,0,0,1-3.6.6,5.84,5.84,0,0,1-2.9-.7,7.31,7.31,0,0,0-2.3-.4,18.13,18.13,0,0,0-3.7.5l-.6.2-.4-.5c-1.3-1.5-5-2.5-6.3-2.8l-.3-.1a3.14,3.14,0,0,0-1.8-.7.37.37,0,0,0-.3.1,1.49,1.49,0,0,0-.6.7l-.2.8h-2.1c-7.4,0-9.5-4.6-9.6-4.8l-.2-.5.2-.4c1.2-2.2,1.5-4,1-5.5a4.65,4.65,0,0,0-1.9-2.3,4.86,4.86,0,0,1-3.5-2,3,3,0,0,1-.4-1.1,6.62,6.62,0,0,0-1.5-.1c-.6,0-1.2.1-1.7.1l-1.2,6.6c.7,15.5-21.8,47-21.8,47L9.55,105l.3,2.8C17.55,111.3,64.35,122.4,96,129.4Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M93.05,296.7c4-3,5.2-4.4,5.5-5h-.2a7.72,7.72,0,0,0-1.5.2l-1.3.3v-8.4l.5-.3c3.4-2,2.6-6.7,2.6-6.8v-.3l.1-.2c1.2-3.9,4.9-5.6,5.8-6,.7-.5,1.5-1.3,1.5-2.1a3,3,0,0,0-1.5-1.8c-1.9-1.2-2.9-7.6-3.3-10.6l-55-82.1L59,123c-19.8-4.7-41.4-10.1-49-13l.3,2.7c.9,9.8-7.2,18.5-7.2,18.5-3.7.7-3,5.9-3,5.9,8.7,21.8,1.1,17.2,1.1,17.2v7.8l6.1,12.6s-1.1,4.8,0,4.8,3.5,4.1,3.5,4.1l.9-2.6c5.9-3.5,3.3,2.4,3.3,2.4l-2-.4,1.7,2.8c2.6,9.8-2.4,4.1-2.4,4.1.2-10.9-2.6.4-2.6.4-1.5,3.5,2.4,10.5,2.4,10.5,7.6.2,2.4,7,2.4,7-3.7.9-2,5-2,5,1.1,0,3.9,5.5,3.9,5.5-1.7.7,1.5,6.7,1.5,6.7-.4,1.3,6.1,12.2,6.1,12.2l-2.6,8.5,1.1,2.8c12.6,1.5,15.9,7.2,15.9,7.2.9,4.6,4.1,5.4,4.1,5.4,7.6-.9,6.8,7,6.8,7,11.3,2,12,14.8,12,14.8v10Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M119,224.8l-1.7-.3h-.2l-.4-.1-2.4,11.1c1.5,3.8,1.6,5.9.3,6.8a2.13,2.13,0,0,1-1.2.3c-1.7,0-4.1-1.6-6-3.2-2.3,11.1-3.3,14.9-3.9,16.2.6,4.8,1.7,8.7,2.2,9.1,0,0,2.4,1.3,2.5,3.5.1,1.4-.7,2.8-2.4,4l-.2.1a8.09,8.09,0,0,0-4.7,4.5c.1,1.2.4,5.8-3.1,8.3v4.6h.7c1.5,0,2.1.6,2.3,1.1.6,1.2-.1,2.7-4.7,6.4l-1.7,3,49.2,29.6,26.2,4,13-98.1c-8.7-.8-55-9.3-63.8-10.9Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M167.75,163.9l-1.1-.1,3.1-17.5c-5.8-.3-20.4-3.1-35.1-6.2l-17.6,82.2,1.7.3c23.2,4.3,58,10.5,64.3,10.9l8.8-66.5C182.55,165.9,174.05,164.7,167.75,163.9Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M350,48.4c-.1,5.5.4,10.8,1.2,11.4.5.2,2.3,1.8,1.8,10.7,0,2.9.4,8.5,1.7,11.2a7.24,7.24,0,0,1,.9,2.4,10,10,0,0,1,0,9.2v3.1l-.8.2c-1.5.3-2.1.8-2.2,1.1-.1.9,1.9,2.9,4.4,4.4l.5.3v31.1l17.2.2,29.5-1.5h16.1l-.7-5.2-7.5-5-.1-.4c-.4-2-3.4-3-3.5-3l-.5-.1-.2-.5c-.7-1.7-4.3-3.5-6.4-4.1l-.4-.1-.2-.4c-1.5-2.9-.1-9.3.1-10l.1-.2c.4-.8.8-1.9.4-2.6a2.75,2.75,0,0,0-2-1l-1-.1.1-1c.2-3.5,1.3-6.2,3.3-7.9a7.49,7.49,0,0,1,4.5-1.8l3.5-7.1c-1.9-.3-2.1-1.3-1.5-.9,1.3.9,11.9-10.2,11.9-10.2-1.3-5.3,17.2-13.2,17.2-13.2-6.6-4-9.7,0-9.7,0-3.5-.4-4-3.1-4-3.1-3.1,3.5-8.8,3.1-8.8,3.1-2.7-4.4-8.8-4.4-8.8-4.4a14.38,14.38,0,0,0-10.6-4.4c-4,5.8-6.6,0-6.6,0-10.6.4-10.6-4-10.6-4,.4-9.3-4.4-8.8-4.4-8.8v8c-3.5.9-11.9,1.1-23.1.7A9.28,9.28,0,0,1,350,48.4Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M34.85,36.6l-.3,1.9c.4,0,.8-.1,1.3-.1a8.53,8.53,0,0,1,3,.5l.9.4-.3.9h0a1.75,1.75,0,0,0,.1.5,3.23,3.23,0,0,0,2.2,1H42a5.87,5.87,0,0,1,3.2,3.6c.7,1.9.4,4.2-.9,6.7.6,1,2.6,3.1,7.4,3.1h.4a3,3,0,0,1,1.5-1.4,2.34,2.34,0,0,1,1.2-.2,5.56,5.56,0,0,1,2.6.8c1,.2,4.9,1.1,6.9,3a14.9,14.9,0,0,1,3.6-.4,8.11,8.11,0,0,1,3.3.6l.2.1a3,3,0,0,0,1.8.4,13.19,13.19,0,0,0,3.1-.5h.2c.7,0,2.4-.1,4.4-.1,3.3,0,5.4.2,6.4.7H89c4.5,0,7.3.6,8.4,1.9L112,62.9a4.91,4.91,0,0,1-.1-1.1c-1.1-4.4,7.6-37.7,9.9-46.6C87.05,7.1,61.65,0,61.65,0c3.3,5.9,1.5,10.2,1.5,10.2a4,4,0,0,1-.7,3c2.6,11.3-2.8.9-2.8.9-.9-2.4,1.7-3,1.7-3-3.5-2.8-3,3.3-3,3.3-10.2-.2-18.5-8.7-18.5-8.7-5.9-6.5-2.8,7-2.8,7V25a5.61,5.61,0,0,0,3,4.6c-6.8-.4-3.9,2.4-3.9,2.4C41.35,34.6,34.85,36.6,34.85,36.6Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M242.75,429.9s-2.1-5.1,1.5-1.8a45.68,45.68,0,0,0,5.4,1.8,10.88,10.88,0,0,0,3.3,1.9s1.9.3,3,5.5a5.09,5.09,0,0,0,3.2,1.7,6.49,6.49,0,0,1-4.1,3.6s-4.1,1.8-4.4.8c0,0-4.5,3.4-4.1,5,0,0-2.1,2.2-4.5-.7,0,0-.6-4.1,0-4.7,0,0-1.5-6.5-2.7-6.7v-2s2.9-1,3.5-2.9Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M232.85,421a16.29,16.29,0,0,0,5.2-1s4.8-2.8,0-2.8c0,0,1.1-2.3-5.2-1.8,0,0-2.3-1.5-2.3-1.9,0,0-2.2-.4-1.9,1,0,0-.2,1.9.8,2.2C229.45,416.8,232.85,419.4,232.85,421Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M224.75,418.6s1.8-.5,1.8-1.3c0,0-.2-1.5-.9-1.6h-3.1a2.89,2.89,0,0,1,.9,1.6S223.45,418.2,224.75,418.6Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M219.35,410.5s2.8.9,4.2,0c0,0,4.5-.9,4.4.4,0,0,.6,1.8-3.4,2.6,0,0,.1-1.1-4.5-1.1C220.05,412.4,217.55,411.3,219.35,410.5Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M212.55,408c.5-.4,0,1.3,0,1.3-1.3.8-3.4,0-3.4,0,.8-1.4-1.1-.8-1.1-.8-1.1.3-3.2-.6-3.2-.6-1.3-.6-1.3-3.3-1.3-3.3-.3-2.2,4-2.8,4-2.8,2.4.2,2.3,4.1,2.3,4.1S211.35,408.8,212.55,408Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M185.65,395.8s.5,3-2.4,3c0,0-4.1-1.4-4.7-2.3,0,0,.3-3.5,2.7-3.3h2.5S187,393,185.65,395.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M173.35,398.8s-2.1,2-2.5,1.7l-.5-.3s.5-2.4,1.5-2.5c0,0,2-1.4,2.3-.6A3.74,3.74,0,0,1,173.35,398.8Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M568.45,142l8.5-2.3,22.2-4.4,10-1.7,7.6-1.5a16.52,16.52,0,0,1,5.3-1.1,4.63,4.63,0,0,1,2.5.6,2.54,2.54,0,0,1,1.1,1.2l2-.1.1,1c0,.2.5,4.8,4.6,5.1h.2l2.9,1.2c.1,0,6.5,2.9,10.8,3.7.8-2.9-.2-11.9-1.2-18l.1-12.8-3-10.9a4,4,0,0,1-2.7-1.9,4.51,4.51,0,0,1-.2-3.2c-1.4-1.3-2.2-4.5-2.5-5.4l-.1-.3.1-.3a14,14,0,0,0,0-5c-1.1-.9-1.8-3.4-2.3-7.7-5.5,1.1-5.5,3.4-5.5,3.4-10.6-.7-11.9,3.8-11.9,3.8-2.2,4-11.9,15-11.9,15a5,5,0,0,1,3.5,5.3c1.8,1.3,4.9,7.1,0,5.7s-5.7,6.2-5.7,6.2c-5.7-1.3-8.4,2.2-8.4,2.2-4.9-4.4-11,0-11,0-16.3-1.8-5.3,8.8-5.3,8.8,4.4,1.3-9.7,11.5-9.7,12.4,0,.1-.1.2-.2.3v.7Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M666.25,140.7c-.4,2.4-9.4,5.4-9.4,5.4-4.8-.4-5.4,2-5.4,2a17.24,17.24,0,0,1-1.8,1.6l-1.6,4.7c11.7-.9,21.2-13.2,21.2-13.2Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
              <UsaState d="M111.65,413.9V348.5l-6.4-3.2s-2.3,0-2.9,1.5c0,0-5.5-3.5-6.7-1.5s-4.6-4.1-4.6-4.1h-6.4s-1.5-2.9-1.5-4.6c0,0-2.6,2.3-4.1,0,0,0,2.3-.9-4.4-4.1,0,0-.9,3.5-3.8,2.6l-4.1-.6s-1.5,3.2-6.4,2.6a30.93,30.93,0,0,0-5.2,5.5,9.61,9.61,0,0,1-7.6.6s-3.8,2-1.5,3.8c0,0,7,7.3,3.2,9.3,0,0,4.1.3,2.6,3.5,0,0,6.4,8.4-2,4.6,0,0-8.1-1.2-2.6-4.9l-4.9-.3s-.9,2.9-4.6,1.5h-4.1s.9,3.2,3.5,3.8c0,0-2.9,7,2.3,8.7a13.86,13.86,0,0,1,6.7,0,3.11,3.11,0,0,0,4.1,0s4.9-.6,1.5,3.8c0,0-.3,6.7-4.6,6.4,0,0-2.3-2.6-4.4.6,0,0-2.3.6-4.1-2,0,0-2.9.6-3.5,2,0,0-3.8,5.5-5.2,4.6,0,0-3.2,4.4,0,5.2,0,0,1.8,2-1.4,4.6a39.21,39.21,0,0,1,2.9,6.4h4.4l1.5-1.5s3.8,6.4-.6,7c0,0,1.5,5.8,0,5.8,0,0,4.6-4.4,6.1,0,0,0,2.6-1.5,3.2,4.6,0,0-.3-4.6,2.9-2,0,0,6.4-1.2,2.6,2,0,0-2.3,8.1-5.5,7.8,0,0-3.2,1.7-2.3,3.2,0,0-7.3,0-9.6,3.5,0,0-3.1-2.6-5.5,0,0,0-6.1,3.8-9.3,2.6,0,0-10.5.6,0,2.8a4.83,4.83,0,0,0,5.5-1.6s1.2.3,5.2-1.7a8.89,8.89,0,0,0,8.4-.9s9.3,1.7,4.4-2c0,0,3.5-2,5.8-1.7,0,0,10.2-3.8,11.6-5.2,0,0,9-4.9,4.4-7.3,0,0-2.3-4.4,2.3-4.6,0,0,2.3,1.5,5.2-4.9,0,0,4.6-8.4,9.6-2.6,0,0,4.1.6-4.1,1.7,0,0-5.2,6.7-4.1,7,0,0,4.9.6-.6,2.3,0,0-.9,1.5,3.8,1.2,0,0,6.4-4.1,5.2-4.9s1.2.9,1.2.9,3.2-1.7,3.8.6l-.3-6.4s7.6-4.1,8.7,3.5c0,0,4.1.9,5.8,3.2,0,0,8.1-1.7,10.5,2.9,0,0,3.5,0,4.6-.9,0,0,1.7-2.9,0,2.3,0,0,11,5.8,12.2,8.4,0,0,1.8.1,0,2.6,0,0,2.2.7,2.2,1.7v.9s5.6,6.4,5.2,8.2c0,0,2.2,2.6,3.9,0,0,0,3,.6,1.9,2.8l2.1,1.5v2.8l2.1,3.4s.2-2.4,2.8,0c0,0-4.9-10.5,2.4-4.9,0,0,2.6-.5,2.6,3.4,0,0,6.4-5.6,0-10.9,0,0-6.4-.7-7.1-2.8,0,0,.9-2.4-4.3-6,0,0-1.5-5.4-6-7.7a19.82,19.82,0,0,0-2.6-3.4s-3.6-7.1-5.2,2.1c0,0-3.6,3.4-4.5,0,0,0-3.4-4.5-5.4-5.8s-.2-2.4-.2-2.4-3.4.9-4.1,1.1S111.65,417.7,111.65,413.9Z" stroke="blue" fill="white"  isMounted={isMounted} hasTransitionedIn = {hasTransitionedIn}></UsaState>
          </g>
      </g>
  </svg>
  
            
          
        </div>
        
      </div>
    );
  }
  
  function UsaState(props){
    const dela = (Math.random()*5).toString();
  
    const tran = ("opacity "+dela.toString()+"s ease , transform "+dela.toString()+"s ease-in");
    return <>
    <path d={props.d} stroke="#367bdc" fill = "#367bdc"  style = {{transition: tran}} className={`card ${props.hasTransitionedIn && "in"} ${
                props.isMounted && "visible"}`}></path>
  
    </>
  }