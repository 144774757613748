import React, { useRef } from "react";
import "./style.css";
import { useState } from "react";

import useMountTransition from "../locations/useMountTransition";
import { useIsVisible } from "react-is-visible";

const svgStyle = {
  width: "100vw",
  height: "80vh",
};

export default function Animation({ steps }) {
  const [isMounted, setIsMounted] = useState(true);
  const hasTransitionedIn = useMountTransition(isMounted, 1000);
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);

  return (
    <>
      <div>
        <div className="works-mob">
          <div className="works-mob__item">
            <svg
              width="68"
              height="68"
              viewBox="21 20 68 68"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <circle cx="55" cy="54.2637" r="34" fill="#0193D8"></circle>
              </g>
              <path
                d="M50.6276 63.5997C54.8996 63.5997 58.0196 60.4077 58.0196 54.8637C58.0196 49.3197 54.8996 46.1277 50.6276 46.1277C46.3316 46.1277 43.2116 49.3197 43.2116 54.8637C43.2116 60.4077 46.3316 63.5997 50.6276 63.5997ZM50.6276 59.7117C49.0916 59.7117 47.9876 58.3917 47.9876 54.8637C47.9876 51.3357 49.0916 50.0157 50.6276 50.0157C52.1396 50.0157 53.2436 51.3357 53.2436 54.8637C53.2436 58.3917 52.1396 59.7117 50.6276 59.7117ZM59.0218 46.4637V50.1357H62.1418V63.2637H66.8938V46.4637H59.0218Z"
                fill="white"
              ></path>
            </svg>

            <div className="works-mob__item-txt">
              {steps[0].split("|").slice(0, 1).join(" ")} <br />
              {steps[0].split("|").slice(1).join(" ")}
            </div>
          </div>
          <div className="works-mob__item">
            <svg
              width="68"
              height="68"
              viewBox="21 20 68 68"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <circle cx="55" cy="54.2637" r="34" fill="#34A9DF"></circle>
              </g>
              <path
                d="M48.3073 63.5997C52.5793 63.5997 55.6993 60.4077 55.6993 54.8637C55.6993 49.3197 52.5793 46.1277 48.3073 46.1277C44.0113 46.1277 40.8913 49.3197 40.8913 54.8637C40.8913 60.4077 44.0113 63.5997 48.3073 63.5997ZM48.3073 59.7117C46.7713 59.7117 45.6673 58.3917 45.6673 54.8637C45.6673 51.3357 46.7713 50.0157 48.3073 50.0157C49.8193 50.0157 50.9233 51.3357 50.9233 54.8637C50.9233 58.3917 49.8193 59.7117 48.3073 59.7117ZM63.8775 59.4957L67.0455 56.5677C69.4455 54.3597 69.9015 52.8717 69.9015 51.2397C69.9015 48.1197 67.2375 46.1277 63.3735 46.1277C60.0615 46.1277 57.6855 47.5197 56.4135 49.6317L59.9415 51.5997C60.6135 50.5197 61.7175 49.9677 62.9415 49.9677C64.3815 49.9677 65.1015 50.6157 65.1015 51.6957C65.1015 52.3917 64.9095 53.1597 63.5655 54.4077L57.2775 60.2877V63.2637H70.3575V59.4957H63.8775Z"
                fill="white"
              ></path>
            </svg>

            <div className="works-mob__item-txt">
              {steps[1].split("|").slice(0, 1).join(" ")} <br />
              {steps[1].split("|").slice(1).join(" ")}
            </div>
          </div>



          <div className="works-mob__item">
            <svg
              width="68"
              height="68"
              viewBox="21 20 68 68"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <circle cx="55" cy="54.2637" r="34" fill="#37B8CC"></circle>
              </g>
              <path
                d="M48.2839 63.336C52.5559 63.336 55.6759 60.144 55.6759 54.6C55.6759 49.056 52.5559 45.864 48.2839 45.864C43.9879 45.864 40.8679 49.056 40.8679 54.6C40.8679 60.144 43.9879 63.336 48.2839 63.336ZM48.2839 59.448C46.7479 59.448 45.6439 58.128 45.6439 54.6C45.6439 51.072 46.7479 49.752 48.2839 49.752C49.7959 49.752 50.8999 51.072 50.8999 54.6C50.8999 58.128 49.7959 59.448 48.2839 59.448ZM65.9192 52.92L69.3992 49.176V46.2H57.2552V49.872H63.8792L60.8792 53.112V56.112H62.8712C64.6952 56.112 65.4392 56.736 65.4392 57.744C65.4392 58.872 64.3832 59.496 62.7752 59.496C61.1912 59.496 59.4632 59.016 58.1192 58.104L56.3912 61.68C58.1192 62.76 60.5912 63.336 62.9192 63.336C67.9832 63.336 70.2392 60.6 70.2392 57.744C70.2392 55.344 68.7512 53.52 65.9192 52.92Z"
                fill="white"
              ></path>
            </svg>

            <div className="works-mob__item-txt">
              {steps[2].split("|").slice(0, 1).join(" ")} <br />
              {steps[2].split("|").slice(1).join(" ")}
            </div>
          </div>


          <div className="works-mob__item">
            <svg
              width="68"
              height="68"
              viewBox="21 20 68 68"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <circle cx="55" cy="54.2637" r="34" fill="#39C2BF"></circle>
              </g>
              <path
                d="M46.4948 63.5997C50.7668 63.5997 53.8868 60.4077 53.8868 54.8637C53.8868 49.3197 50.7668 46.1277 46.4948 46.1277C42.1988 46.1277 39.0788 49.3197 39.0788 54.8637C39.0788 60.4077 42.1988 63.5997 46.4948 63.5997ZM46.4948 59.7117C44.9588 59.7117 43.8548 58.3917 43.8548 54.8637C43.8548 51.3357 44.9588 50.0157 46.4948 50.0157C48.0068 50.0157 49.1108 51.3357 49.1108 54.8637C49.1108 58.3917 48.0068 59.7117 46.4948 59.7117ZM71.5885 56.2797H69.0925V53.4237H64.6045V56.2797H61.1965L68.0365 46.4637H63.1165L55.5805 56.9517V60.0477H64.4605V63.2637H69.0925V60.0477H71.5885V56.2797Z"
                fill="white"
              ></path>
            </svg>

            <div className="works-mob__item-txt">
              {steps[3].split("|").slice(0, 1).join(" ")} <br />
              {steps[3].split("|").slice(1).join(" ")}
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
